export const DEAL_ACTION_BUTTON_ADDNOTE_FRAGMENT = `
  fragment dealActionButtonAddnoteFragment on Deal {
    __typename
    id
    uid
    name
    displayFileResource {
      schemaCode
      path
    }
  }
`;
